"use client";
import ButtonWithLoading from "@/components/ui/ButtonWithLoading";
import SimpleInputValidIndicator from "@/components/ui/Input/SimpleInputValidIndicator";
import SimpleTextInput from "@/components/ui/Input/SimpleTextInput";
import EmailInvalidIndicator from "@/components/ui/Login/EmailInvalidIndicator";
import LoginWithGoogle from "@/components/ui/Login/LoginWithGoogle";
import AtSVG from "@/components/ui/SVGIcons/AtSVG";
import FingerprintSVG from "@/components/ui/SVGIcons/FingerprintSVG";
import { IBackendManagerInterface } from "@/utils/BackendManager";
import { getSignupPath } from "@/utils/NavigationUtils";
import { toastError } from "@/utils/ToastsManager";
import { emailRegex } from "@mltask/core/Utils/RegexHelper";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter, useSearchParams } from "next/navigation";
import { useState } from "react";
import { useForm } from "react-hook-form";

const ConfirmSignUp = dynamic(
  () => import("@/components/ui/Signup/ConfirmSignUp"),
  {
    ssr: false,
  }
);

var BackendManager: IBackendManagerInterface;
import("@/utils/BackendManager").then((lib) => {
  BackendManager = lib.default;
});

export default function LoginPageUI() {
  const [showingConfirmation, setShowingConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const router = useRouter();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const searchParams = useSearchParams();

  function logInFunction() {
    setLoading(true);
    BackendManager.logIn(email, password)
      .then((user) => {
        setLoading(false);
        router.push("/");
      })
      .catch((error) => {
        if (error.code == "UserNotConfirmedException") {
          setShowingConfirmation(true);
        } else {
          // error.code == "UserNotFoundException"
          console.error("Failed to login with error:", error);
          toastError();
        }
        // console.log("Failed to login with error: " + JSON.stringify(error));
        setLoading(false);
      });
  }
  function renderThirdPartyLoginMethods() {
    return (
      <div className="flex">
        <LoginWithGoogle
          isLoading={loading}
          onClickCallback={async () => {
            setLoading(true);
            await BackendManager.signInWithGoogle(
              searchParams.get("mlapp") ?? undefined
            );
            setLoading(false);
          }}
        />
      </div>
    );
  }

  function renderManualLoginMethod() {
    return (
      <form
        noValidate
        onSubmit={handleSubmit((data) => {
          logInFunction();
        })}
      >
        <div className="ng-untouched ng-pristine ng-valid space-y-8">
          <SimpleTextInput
            registrar={register("email", {
              required: true,
              pattern: emailRegex,
              onChange: (e) => {
                setEmail(e.target.value);
              },
            })}
            errorSection={
              errors["email"] && <EmailInvalidIndicator email={email} />
            }
            inputType="email"
            title="Your Email"
            placeholder="Ex. john@smith.com"
            value={email}
            SVGELement={<AtSVG size={5} strokeWidth={2} />}
          />

          <SimpleTextInput
            registrar={register("password", {
              required: true,
              minLength: 8,
              onChange: (e) => {
                setPassword(e.target.value);
              },
            })}
            errorSection={
              errors.password && (
                <SimpleInputValidIndicator
                  text={`Please enter a valid password`}
                />
              )
            }
            inputType="password"
            title="Password"
            placeholder="Forgot password?"
            value={password}
            SVGELement={<FingerprintSVG size={5} strokeWidth={2} />}
            supplementaryAnchorHref="/resetpassword"
            supplementaryAnchorText="Forgot password?"
          />
          <ButtonWithLoading
            isLoading={loading}
            type="submit"
            className={`btn inline-flex w-full items-center justify-center  border border-transparent bg-gradient-to-r from-primary to-[color-mix(in_oklab,oklch(var(--p)),black_7%)] text-base font-semibold text-primary-content transition-all duration-200 hover:opacity-90 focus:opacity-90 focus:outline-none`}
          >
            Log in
          </ButtonWithLoading>

          <div></div>
        </div>
      </form>
    );
  }
  function renderTitle() {
    let finalRedirectURL = `${getSignupPath()}`;
    if (searchParams.get("mlapp")) {
      finalRedirectURL += `?mlapp=${searchParams.get("mlapp")}`;
    }
    return (
      <div className="mb-8">
        <h2 className="text-center text-3xl font-bold leading-tight transition-all sm:text-4xl">
          Log in to your account
        </h2>
        <p className="mt-2 text-center text-lg ">
          {`Don't have account? `}
          <Link
            className="font-medium text-primary transition-all duration-200 hover:text-[color-mix(in_oklab,oklch(var(--p)),black_7%)] hover:underline "
            href={finalRedirectURL}
          >
            Sign up here
          </Link>
        </p>
      </div>
    );
  }
  function loginSection() {
    return (
      <div className="w-full">
        <div className="flex flex-col items-center justify-center ">
          <div className="w-full rounded-lg bg-base-100 p-8 text-base-content shadow-md shadow-neutral ">
            {renderTitle()}
            {renderThirdPartyLoginMethods()}
            <div className="divider my-8 h-px  p-0 font-bold ">
              <span className="px-2 ">Or</span>
            </div>
            {renderManualLoginMethod()}
          </div>
        </div>
      </div>
    );
  }
  function renderLoginSection() {
    if (showingConfirmation) return;
    return loginSection();
  }
  function renderShowingConfirmation() {
    if (showingConfirmation == false) return;
    return (
      <ConfirmSignUp
        email={email}
        password={password}
        isVerifyingWithLink={true}
      />
    );
  }
  return (
    <section className="mx-auto my-auto flex max-w-xl grow p-4">
      {renderLoginSection()}
      {renderShowingConfirmation()}
    </section>
  );
}
