"use client";
import { Transition } from "@headlessui/react";
import Link from "next/link";
import { ReactElement } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

type TextInputProp = {
  title?: string;
  titleTWClass?: string;
  additionalTitleElement?: ReactElement;
  inputType: string;
  placeholder: string;
  value?: string | ReadonlyArray<string> | number | undefined;
  inputBadge?: string;
  additionalInputClassName?: string;
  onBlur?: () => void;
  onPaste?: (str: string) => void;
  onChange?: (str: string) => void;
  pathD?: string;
  SVGELement?: ReactElement;
  supplementaryAnchorText?: string | null;
  supplementaryAnchorHref?: string | null;
  registrar?: UseFormRegisterReturn | null;
  errorSection?: ReactElement;
  onSupplementaryAnchorClick?: (() => void) | null;
};
export default function SimpleTextInput({
  inputType,
  title,
  titleTWClass,
  additionalTitleElement,
  placeholder,
  value,
  inputBadge,
  additionalInputClassName,
  onBlur,
  onPaste,
  onChange,
  pathD = undefined,
  SVGELement = undefined,
  registrar,
  errorSection,
  supplementaryAnchorText = null,
  supplementaryAnchorHref = null,
  onSupplementaryAnchorClick = null,
  ...inputProps
}: TextInputProp) {
  // function renderValidationSection() {
  //   if (errors == null) return null;
  //   const validRegistrar = registrar;
  //   if (validRegistrar == null) return null;
  //   const registrarName = validRegistrar.name;

  //   // console.log(errors.email);
  //   return (
  //     <div>
  //       {errors[registrarName] != null && <p>enter a valid {registrarName}.</p>}
  //     </div>
  //   );
  // }
  function renderInputBadge() {
    if (inputBadge == undefined) return;
    return (
      <Transition
        show={inputBadge.length > 0}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute rounded-br-xl rounded-tl-md bg-secondary px-2 py-px text-xs font-bold text-secondary-content shadow-md">
          <span>FREE</span>
        </div>
      </Transition>
    );
  }
  function renderSideIcon() {
    return (
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        {SVGELement && SVGELement}
        {pathD && (
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={pathD}
            />
          </svg>
        )}
      </div>
    );
  }
  function renderSupplementaryAnchor() {
    if (supplementaryAnchorText == undefined) return;
    return (
      <Link
        onClick={() => {
          onSupplementaryAnchorClick?.();
        }}
        href={supplementaryAnchorHref!}
        title=""
        className="text-sm font-medium text-primary hover:text-[color-mix(in_oklab,oklch(var(--p)),black_7%)] hover:underline focus:text-accent"
      >
        {supplementaryAnchorText}
      </Link>
    );
  }
  return (
    <div className="flex grow flex-col">
      <div className="flex items-center justify-between">
        {title && (
          <label
            htmlFor={placeholder}
            className={`font-medium text-base-content ${titleTWClass}`}
          >
            {title}
          </label>
        )}

        {additionalTitleElement && additionalTitleElement}
        {/* <div className="tooltip ml-2 mr-auto" data-tip="hello">
          <BsInfoLg className="text-primary-content" />
        </div>
         */}

        {renderSupplementaryAnchor()}
      </div>

      <div className="relative mt-2.5 w-full text-base-100">
        {renderSideIcon()}
        {renderInputBadge()}

        <input
          {...(registrar || {})}
          onBlur={(target: any) => {
            registrar?.onBlur(target);
            onBlur?.();
          }}
          onPaste={(event) => {
            onPaste?.(event.clipboardData.getData("text"));
          }}
          onChange={(event) => {
            if (registrar?.onChange) {
              registrar.onChange(event);
            } else if (onChange) {
              onChange(event.target.value);
            }
          }}
          value={value}
          type={inputType}
          inputMode={inputType === "number" ? "numeric" : "text"}
          pattern={inputType === "number" ? "[0-9]*" : undefined}
          autoComplete="on"
          id={placeholder}
          placeholder={placeholder}
          className={`input w-full bg-base-content py-4 pl-10 pr-4 shadow-xl hover:input-bordered ${additionalInputClassName}`}
        />
      </div>

      {errorSection}
    </div>
  );
}
