"use client";
import React from "react";
import ButtonWithLoading from "../ButtonWithLoading";
import GoogleLogoSVG from "../SVGIcons/GoogleLogoSVG";

type Props = { isLoading: boolean; onClickCallback: () => void };

export default function LoginWithGoogle({ isLoading, onClickCallback }: Props) {
  return (
    <ButtonWithLoading
      onClick={onClickCallback}
      className={`btn btn-accent text-accent-content ${
        isLoading ? "btn-disabled" : ""
      } md:text-md xs:text-xs btn w-full text-xs transition-all sm:text-sm lg:text-lg xl:text-xl 2xl:text-2xl`}
    >
      {isLoading == false && <GoogleLogoSVG size={8} />}
      Google
    </ButtonWithLoading>
  );
}
